export enum StatusType {
  Wfh = "wfh",
  Office = "office",
  NotWorking = "not_working",
  Sick = "sick",
  Other = "other",
}

export const StatusTypeLabel = {
  [StatusType.Wfh]: "WFH",
  [StatusType.Office]: "Office",
  [StatusType.NotWorking]: "Not Working",
  [StatusType.Sick]: "Sick",
  [StatusType.Other]: "Other",
};

export const StatusTypeEmoji = {
  [StatusType.Wfh]: "🏠",
  [StatusType.Office]: "🏢",
  [StatusType.NotWorking]: "🔴",
  [StatusType.Sick]: "🤒",
  [StatusType.Other]: "🏖️",
};

export type Status = {
  id: string;
  user_id: string;
  type: StatusType;
  date: string;
};
