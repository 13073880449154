import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import {
  Status,
  StatusType,
  StatusTypeEmoji,
  StatusTypeLabel,
} from "../../types/statuses";
import { Box, Select } from "@chakra-ui/react";
import { isEqual } from "lodash";

interface Props {
  status: Status;
  onChange?: (newStatus: Status) => void;
}

function StatusSelect({ status, onChange }: Props) {
  const [value, setValue] = useState(status?.type);
  const handleChange = (e: any) => {
    setValue(e.target.value);
    if (onChange) {
      onChange({
        ...status,
        type: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (!isEqual(value, status.type)) {
      setValue(status.type);
    }
  }, [status?.type]);

  return (
    <Box borderRadius={10} overflow={"hidden"} boxShadow={"base"}>
      <Box bg={"primary"} color={"white"} p={1} textAlign={"center"}>
        {format(new Date(status.date), "EEE do")}
      </Box>
      <Box p={3}>
        <Select placeholder={"Choose"} value={value} onChange={handleChange}>
          {Object.values(StatusType).map((type) => {
            return (
              <option value={type} key={status.date + type}>
                {StatusTypeLabel[type]} {StatusTypeEmoji[type]}
              </option>
            );
          })}
        </Select>
      </Box>
    </Box>
  );
}

export default StatusSelect;
