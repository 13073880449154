import React from "react";
import { KeyValueMap } from "../../utils/objects";
import { Status, StatusType, StatusTypeLabel } from "../../types/statuses";
import { format } from "date-fns";
import { DEFAULT_DATE_FORMAT } from "../../utils/dates";
import StatusSelect from "../StatusSelect/StatusSelect";
import { IAuthenticationContextValue } from "../../contexts/AuthenticationContext";
import {
  Box,
  BoxProps,
  Flex,
  Heading,
  Text,
  Wrap,
  Select,
  Center,
} from "@chakra-ui/react";

interface Props extends BoxProps {
  statuses: KeyValueMap<Status>;
  week: Date[];
  user: IAuthenticationContextValue["user"];
  onStatusChange?: (newStatus: Status) => void;
  onWeekChange?: (newType: StatusType) => any;
}

function StatusSelectWeek({
  statuses,
  week,
  user,
  onStatusChange,
  onWeekChange,
  ...props
}: Props) {
  return (
    <Box {...props}>
      <Heading fontSize={"2xl"} mb={3}>
        Week beginning {format(week[0], "do MMMM")}
      </Heading>
      <Flex alignItems={"center"} mb={3}>
        <Text mr={2}>Set all to: </Text>
        <Select
          width={120}
          placeholder={"Choose"}
          onChange={(e) => onWeekChange?.(e.target.value as StatusType)}
        >
          {Object.values(StatusType).map((type) => {
            return (
              <option value={type} key={`weekly-${type}`}>
                {StatusTypeLabel[type]}
              </option>
            );
          })}
        </Select>
      </Flex>
      <Wrap spacing={3}>
        {week.map((day) => {
          const dayFormatted = format(day, DEFAULT_DATE_FORMAT);
          const savedStatus = statuses[dayFormatted];
          return (
            <StatusSelect
              key={dayFormatted}
              status={
                savedStatus || {
                  date: dayFormatted,
                  type: "",
                  user_id: user.id,
                  id: "",
                }
              }
              onChange={onStatusChange}
            />
          );
        })}
      </Wrap>
    </Box>
  );
}

export default StatusSelectWeek;
