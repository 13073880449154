import React, { ComponentType } from "react";
import { Redirect, RouteComponentProps } from "@reach/router";
import { useIsAuthenticated } from "../../contexts/AuthenticationContext";
import { cleanUrl } from "../../utils/urls";

interface Props extends RouteComponentProps {
  Page: ComponentType<any>;
}

function AuthenticatedRoute({ Page, ...props }: Props) {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Redirect to={"/slack/auth"} noThrow />;
  }

  cleanUrl();

  return <Page {...props} />;
}

export default AuthenticatedRoute;
