import React from "react";
import { Text, Button, Center, Box } from "@chakra-ui/react";
import { navigate } from "@reach/router";

interface Props {}

function LoginError({}: Props) {
  const onClick = async () => {
    navigate("/slack/auth");
  };
  return (
    <Center minHeight={"100vh"} data-testid={"login-error"}>
      <Box>
        <Text textAlign={"center"} mb={2}>
          Error logging in.
        </Text>
        <Button onClick={onClick}>Back to login page</Button>
      </Box>
    </Center>
  );
}

export default LoginError;
