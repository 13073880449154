import React from "react";
import { useAuthentication } from "../../../../core/contexts/AuthenticationContext";
import {
  ajaxService,
  RequestStatus,
  useAjax,
} from "../../../../core/utils/ajax";
import {
  DEFAULT_DATE_FORMAT,
  getDetailedWeeks,
} from "../../../../core/utils/dates";
import { last } from "lodash";
import { Status, StatusType } from "../../../../core/types/statuses";
import { toKeyValueMap } from "../../../../core/utils/objects";
import StatusSelectWeek from "../../../../core/components/StatusSelectWeek/StatusSelectWeek";
import { format } from "date-fns";
import { mutate } from "swr";
import { Box } from "@chakra-ui/react";

interface Props {}

function AssignStatusSectionContainer({}: Props) {
  const auth = useAuthentication();
  const weeks = getDetailedWeeks(new Date(), 4);
  const [statusesState, statuses] = useAjax(
    `${process.env.REACT_APP_API_URL}/statuses`,
    {
      query: {
        user_id: auth.user.id,
        from: format(weeks[0][0], DEFAULT_DATE_FORMAT),
        to: format(last(last(weeks)) as Date, DEFAULT_DATE_FORMAT),
      },
      defaultValue: [],
    }
  );
  const statusesMap = toKeyValueMap<Status>(statuses, "date");

  const handleStatusChange = async (newStatus: Status) => {
    ajaxService.post(`${process.env.REACT_APP_API_URL}/statuses`, {
      data: {
        user_id: auth.user.id,
        date: newStatus.date,
        type: newStatus.type,
      },
    });
  };
  const handleBulkStatusChange = (week: Date[]) => async (
    newType: StatusType
  ) => {
    const statuses = week.map((date) => {
      return {
        user_id: auth.user.id,
        date: format(date, DEFAULT_DATE_FORMAT),
        type: newType,
      };
    });
    await ajaxService.post(`${process.env.REACT_APP_API_URL}/statuses/bulk`, {
      data: {
        statuses,
      },
    });
    mutate(`${process.env.REACT_APP_API_URL}/statuses`);
  };

  return (
    <Box
      sx={{
        opacity: statusesState !== RequestStatus.Success ? "0.5" : "1",
        pointerEvents:
          statusesState !== RequestStatus.Success ? "none" : "auto",
      }}
    >
      {weeks.map((week, index) => (
        <StatusSelectWeek
          key={`${week[0].toISOString()}${index}`}
          week={week}
          statuses={statusesMap}
          user={auth.user}
          onStatusChange={handleStatusChange}
          onWeekChange={handleBulkStatusChange(week)}
          mb={4}
        />
      ))}
    </Box>
  );
}

export default AssignStatusSectionContainer;
