import React from "react";
import { useAuthentication } from "../../../../core/contexts/AuthenticationContext";
import AssignStatusSectionContainer from "../StatusAssignSection/AssignStatusSectionContainer";
import Layout from "../../../../core/components/Layout/Layout";
import { Heading } from "@chakra-ui/react";

interface Props {}

function HomePage({}: Props) {
  return (
    <Layout>
      <AssignStatusSectionContainer />
    </Layout>
  );
}

export default HomePage;
