import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Container, Center, Box, Heading } from "@chakra-ui/react";

interface Props {}

function LoginPage({}: Props) {
  return (
    <Container centerContent>
      <Center minHeight={"100vh"}>
        <Box>
          <Heading textAlign={"center"} mb={2} fontSize={"4xl"} as={"h1"}>
            OOTO
          </Heading>
          <a
            href={`https://slack.com/oauth/v2/authorize?user_scope=identity.basic identity.avatar profile&scope=users:write&client_id=71047529783.3101025033074&redirect_uri=${process.env.REACT_APP_APP_URL}/slack/auth`}
          >
            <img src="https://api.slack.com/img/sign_in_with_slack.png" />
          </a>
        </Box>
      </Center>
    </Container>
  );
}

export default LoginPage;
