import jwt from "jsonwebtoken";
import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

export const AUTH_TOKEN_COOKIE_NAME = "ooto-token-v3";

export interface IAuthenticationContextValue {
  user: {
    id: string;
    slack_id: string;
    slack_team_id: string;
  };
  slack_auth_token: string;
}

export interface IAuthenticationContext {
  value: IAuthenticationContextValue | null;
  setValue: (newValue: IAuthenticationContextValue) => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext | null>(
  null
);

export function getAuthToken() {
  return Cookies.get(AUTH_TOKEN_COOKIE_NAME);
}

export function getAuthObjectFromCookie(): IAuthenticationContextValue | null {
  const savedToken = getAuthToken();

  if (!savedToken) {
    return null;
  }

  try {
    // todo: is this best way to do this? should it verify via server first?
    return jwt.decode(savedToken) as IAuthenticationContextValue;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export function AuthenticationProvider(props: { children: any }) {
  const value = getAuthObjectFromCookie();
  const [stateValue, setStateValue] = useState(value);

  return (
    <AuthenticationContext.Provider
      value={{
        value: stateValue,
        setValue: setStateValue,
      }}
      {...props}
    />
  );
}

/**
 * gets the actual auth value. Using as assertions because the auth object will be initialised fully before this is going to be used
 */
export function useAuthentication(): IAuthenticationContextValue {
  const context = useContext(AuthenticationContext) as IAuthenticationContext;

  return context.value as IAuthenticationContextValue;
}

export function useIsAuthenticated(): boolean {
  return !!useAuthentication();
}

export function useSetAuthentication(): (token: string) => void {
  const context = useContext(AuthenticationContext);

  return (token: string) => {
    if (context?.setValue) {
      Cookies.set(AUTH_TOKEN_COOKIE_NAME, token, { expires: 30 });
      context.setValue(jwt.decode(token) as IAuthenticationContextValue);
    }
  };
}
