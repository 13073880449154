import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { Router } from "@reach/router";
import AuthenticatedRoute from "./core/components/AuthenticatedRoute/AuthenticatedRoute";
import HomePage from "./pages/home/components/HomePage/HomePage";
import { AuthenticationProvider } from "./core/contexts/AuthenticationContext";
import LoginPageContainer from "./pages/login/components/LoginPage/LoginPageContainer";
import theme from "./core/theme";

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <AuthenticationProvider>
        <Router>
          <LoginPageContainer path={"/slack/auth"} />
          <AuthenticatedRoute path={"/"} Page={HomePage} />
        </Router>
      </AuthenticationProvider>
    </ChakraProvider>
  );
}
