import React, { ReactNode } from "react";
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { logout } from "../../utils/account";

interface Props {
  children: ReactNode;
}

function Layout({ children }: Props) {
  return (
    <Box minHeight={"100vh"}>
      <Box p={4} position={"fixed"} top={0} right={0}>
        <Button onClick={logout}>Logout</Button>
      </Box>
      <Box p={4}>
        <Heading as={"h1"} mb={3}>
          OOTO
        </Heading>
        {children}
      </Box>
    </Box>
  );
}

export default Layout;
