import React from "react";
import { Box, Flex, FlexProps, Spinner, SpinnerProps } from "@chakra-ui/react";

interface Props extends FlexProps {
  size?: SpinnerProps["size"];
}

function Loader({ children, size, ...props }: Props) {
  return (
    <Flex flexDirection={"column"} alignItems={"center"} {...props}>
      <Spinner size={size} />
      {children && <Box mt={2}>{children}</Box>}
    </Flex>
  );
}

export default Loader;
