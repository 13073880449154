import qs from "qs";

export function getQueryParameters(queryString?: string) {
  if (!queryString) {
    return {};
  }

  return qs.parse(queryString.replace(/^\?/, ""));
}

export function cleanUrl() {
  const queryParams = getQueryParameters(window.location.search);

  if (queryParams.code) {
    window.history.replaceState(
      {},
      document.title,
      window.location.href.split("?")[0]
    );
  }
}
