import superagent from "superagent";
import useSWR from "swr";
import { getAuthToken } from "../contexts/AuthenticationContext";

/**
 * Ajax utils that are small wrappers around superagent and swr libraries. Main point is to harmonise the usage of ajax across the app and provide an easy public interface for it
 */

export enum RequestStatus {
  Idle = "idle",
  Loading = "loading",
  Success = "success",
  Error = "error",
}

type AjaxResponse = {
  status: RequestStatus;
  payload?: any;
  message?: string;
};

type AjaxOptions = {
  query?: any;
  data?: any;
  defaultValue?: any;
};

export interface IAjaxService {
  request(
    method: "get" | "post" | "put" | "delete",
    url: string,
    options?: AjaxOptions
  ): Promise<AjaxResponse>;
  get(url: string, options?: AjaxOptions): Promise<AjaxResponse>;
  post(url: string, options?: AjaxOptions): Promise<AjaxResponse>;
}

export const ajaxService: IAjaxService = {
  async request(
    method: string,
    url: string,
    options: AjaxOptions
  ): Promise<AjaxResponse> {
    // superagent ts file doesn't include index signature for export so need to ignore here
    // @ts-ignore
    const request = superagent[method](url);

    if (options?.query) {
      request.query(options.query);
    }

    if (options?.data) {
      request.send(options.data);
    }

    request.set("x-user-token", getAuthToken());

    try {
      const result = await request;

      if (result.error || result.status >= 400) {
        return {
          status: RequestStatus.Error,
          payload: null,
          message: result?.body?.message || "Error with request",
        };
      }

      return {
        status: RequestStatus.Success,
        payload: result?.body,
        message: result?.body?.message,
      };
    } catch (e) {
      return {
        status: RequestStatus.Error,
        payload: null,
        message: "Error with request",
      };
    }
  },
  async get(url, options) {
    return this.request("get", url, options);
  },
  async post(url, options) {
    return this.request("post", url, options);
  },
};

export function useAjax<TPayload = any>(
  url: string,
  options?: AjaxOptions,
  shouldRun = true
): [RequestStatus | null, TPayload | null] {
  const { data, error, isValidating } = useSWR(
    (shouldRun && url) || "",
    (key) => ajaxService.get(key, options),
    {
      revalidateOnFocus: false,
    }
  );

  const defaultValue = options?.defaultValue || null;

  if (error || data?.status === RequestStatus.Error) {
    return [RequestStatus.Error, defaultValue];
  }

  if (isValidating) {
    return [RequestStatus.Loading, defaultValue];
  }

  if (!isValidating && data?.status === RequestStatus.Success) {
    return [RequestStatus.Success, data?.payload];
  }

  return [RequestStatus.Idle, defaultValue];
}
