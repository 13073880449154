export type KeyValueMap<T> = {
  [index: string]: T;
};

export function toKeyValueMap<T = any>(arr: T[], prop: keyof T) {
  return arr.reduce((acc, item) => {
    acc[item[prop] as any] = item;

    return acc;
  }, {} as KeyValueMap<T>);
}
