import { addDays, startOfWeek } from "date-fns";
import { range } from "lodash";

export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";

export function getDetailedWeeks(start: Date, weeks: number) {
  const firstDay = startOfWeek(start, {
    weekStartsOn: 1,
  });

  return range(weeks).map((week) =>
    range(7)
      .map((daysToAdd) => {
        const amountToAdd = week * 7 + daysToAdd;
        return addDays(firstDay, amountToAdd);
      })
      .slice(0, -2)
  );
}
