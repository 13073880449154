import React, { useEffect } from "react";
import { Redirect, RouteComponentProps, useLocation } from "@reach/router";
import LoginPage from "./LoginPage";
import { getQueryParameters } from "../../../../core/utils/urls";
import Loader from "../../../../core/components/Loader/Loader";
import { RequestStatus, useAjax } from "../../../../core/utils/ajax";
import LoginError from "../LoginError/LoginError";
import {
  useIsAuthenticated,
  useSetAuthentication,
} from "../../../../core/contexts/AuthenticationContext";
import { Center } from "@chakra-ui/react";

interface Props extends RouteComponentProps {}

function LoginPageContainer(props: Props) {
  const location = useLocation();
  const queryParams = getQueryParameters(location.search);
  const isLoggedIn = useIsAuthenticated();
  const setAuthentication = useSetAuthentication();
  const [authStatus, authResponse] = useAjax(
    `${process.env.REACT_APP_API_URL}/slack/auth`,
    {
      query: {
        code: queryParams?.code,
      },
    },
    !!queryParams?.code
  );

  useEffect(() => {
    if (authStatus === RequestStatus.Success && authResponse) {
      setAuthentication(authResponse);
    }
  }, [authStatus, authResponse]);

  if (isLoggedIn) {
    return <Redirect to={"/"} noThrow />;
  }

  if (queryParams.code) {
    if (authStatus === RequestStatus.Error) {
      return <LoginError />;
    }

    if (authStatus === RequestStatus.Success && authResponse) {
      return (
        <div data-testid={"home-redirect"}>
          <Redirect to={"/"} noThrow />;
        </div>
      );
    }

    return (
      <Center minHeight={"100vh"}>
        <Loader data-testid={"login-loader"}>Logging In...</Loader>
      </Center>
    );
  }

  return <LoginPage />;
}

export default LoginPageContainer;
